import React, { useEffect } from "react";

const PBIRenderer = ({ reportURL }) => {
  
  return (
    <iframe className="iframepbi"   
      src={reportURL}
      frameBorder="0"
      allowFullScreen
    ></iframe>
  );
};

export default PBIRenderer;
