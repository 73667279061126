import React from "react";
import "./spinner.css";

const Spinner = () => (
  <div className="right-loader-wrapper  d-flex align-items-center justify-content-center">
    <div className="right-loader">
      {/* <div className="right-loader-text">loading</div> */}
      <div className="right-load"></div>
    </div>
  </div>
);

export default Spinner;
