import React, { useState } from "react";
import bs from "../assets/tg-logo.png";

const Header = () => {
  return (
    <div
      style={{ color: "#004C49", fontFamily: "MuseoModerno", height:"5%" }}
      className="d-flex align-items-center justify-content-spacebetween head clearfix"
    >      
      <div style={{ marginBottom: 6, flexGrow:1}}>
        <h1>NOVA</h1>
      </div>
      <div style={{marginTop: 7}}>
        <img className="logo" src={bs} alt="logo"/>
      </div>
     
    </div>
  );
};

export default Header;
